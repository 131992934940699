<template>
  <div class="customer">
    <div class="breadcrumb">{{ $t("customer") }}</div>
    <div>
      <div class="tool-bar">
        <div class="tool-button" @click="handleOpen">
          <a-icon type="plus" />
          <span>新建</span>
        </div>
      </div>
      <n-table :data="staffs" :columns="columns"> </n-table>
    </div>
    <a-modal :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <a-form>
        <a-form-item>
          <a-input
            v-model="first_name"
            v-decorator="['first_name', { rules: [{ required: true }] }]"
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            v-model="last_name"
            v-decorator="['last_name', { rules: [{ required: true }] }]"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import NTable from "../table/index.vue";
import api from "@/api";
export default {
  data() {
    return {
      staffs: [],
      columns: [
        {
          title: "姓氏",
          dataIndex: "last_name",
          width: "50px",
        },
        {
          title: "名称",
          dataIndex: "first_name",
        },
      ],
      visible: false,
      first_name: "",
      last_name: "",
    };
  },
  components: {
    NTable,
  },
  created() {
    this.getStaffs();
  },
  methods: {
    async getStaffs() {
      this.staffs = await api.getStaffs();
    },
    handleOpen() {
      this.visible = true;
    },
    handleOk() {
      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
    handleSave() {
      let data = {};
    },
  },
};
</script>

<style>
.toole-bar {
  height: 5.2rem;
  width: 100%;
}
.tool-button {
  padding: 5px 10px;
  font-size: 1.4rem;
  float: right;
}
.tool-button:hover {
  background: var(--hvrBgColor);
  border-radius: 10px;
  cursor: pointer;
}
</style>
